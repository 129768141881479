import { useState } from "react"
import { Button } from "../components/Button"
import { PlayerIntroScene } from "../../../models/PlayerScene"
import { Input } from "../components/Input"
import { PlayerAPI } from "../PlayerAPI/Interface"
import { Form } from "../components/Form"
import { Subtitle } from "../components/Subtitle"
import { UserInfo } from "../../../models/UserInfo"
import { useLocalStorage } from "../hooks/useLocalStorage"

interface Props {
  scene: PlayerIntroScene
  api: PlayerAPI
  userInfo?: UserInfo
}

export const PlayerIntroScreen = ({ userInfo, api }: Props) => {
  const [savedName, setSavedName] = useLocalStorage<string>(
    "playerName",
    userInfo?.displayName || ""
  )
  const [name, setName] = useState(savedName)

  const isValid = name.length > 0
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        setSavedName(name)
        api.setPlayerName(name)
      }}
    >
      <Subtitle>Introduce yourself</Subtitle>
      <Input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Button disabled={!isValid} type="submit" variant="primary">
        Next
      </Button>
    </Form>
  )
}
