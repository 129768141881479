import mixpanel, { Dict } from "mixpanel-browser"
import { getAnalytics, initializeAnalytics, logEvent } from "firebase/analytics"
import { UserInfo } from "../../../models/UserInfo"
import { getApp } from "firebase/app"

const MIXPANEL_ENABLED = process.env.NODE_ENV === "production"
const FIREBASE_ANALYTICS_ENABLED = process.env.NODE_ENV === "production"

export function initTracking() {
  if (MIXPANEL_ENABLED) {
    mixpanel.init("8b983ec7dfc7d3db4ed537865030981c", {
      api_host: `https://${document.location.hostname}/mp`,
    })
  }
  if (FIREBASE_ANALYTICS_ENABLED) {
    initializeAnalytics(getApp())
  }
}

export function track(eventName: string, properties?: Dict) {
  if (MIXPANEL_ENABLED) {
    mixpanel.track(eventName, properties)
  }
  if (FIREBASE_ANALYTICS_ENABLED) {
    logEvent(getAnalytics(), eventName, properties)
  }
}

export function identify(userInfo: UserInfo) {
  if (MIXPANEL_ENABLED) {
    mixpanel.identify(userInfo.uid)
    if (userInfo.displayName) {
      mixpanel.people.set("$name", userInfo.displayName)
    }
    if (userInfo.email) {
      mixpanel.people.set("$email", userInfo.email)
    }
    if (userInfo.photoURL) {
      mixpanel.people.set("$avatar", userInfo.photoURL)
    }
    if (userInfo.phoneNumber) {
      mixpanel.people.set("$phone", userInfo.phoneNumber)
    }
  }
}
