import { DrawWordsScene } from "../../../models/PlayerScene"
import styled from "styled-components"
import { Button, ButtonBox } from "../components/Button"
import { Centered } from "../components/Centered"
import { PlayerAPI } from "../PlayerAPI/Interface"
import { black, grey, lightGrey } from "../components/colors"
import { Timer } from "../components/Timer"
import { useWakeLock } from "../hooks/useWakeLock"

const CurrentWord = styled.div`
  padding: 10px 15px;

  color: ${black};

  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: center;

  margin-top: 30px;
  margin-bottom: 50px;

  background: ${lightGrey};
  border: 3px dashed ${grey};
  box-sizing: border-box;
  border-radius: 15px;
`

interface Props {
  scene: DrawWordsScene
  api: PlayerAPI
}

export const DrawWordsScreen = ({ scene, api }: Props) => {
  useWakeLock()
  return (
    <Centered>
      <Timer timeLeft={scene.timeLeft} />
      <CurrentWord>{scene.currentWord}</CurrentWord>
      <ButtonBox>
        <Button variant="green" onClick={() => api.markAsCorrect()}>
          Correct
        </Button>
        <Button
          disabled={!scene.canSkip}
          variant="red"
          onClick={() => api.skipWord()}
        >
          Skip
        </Button>
      </ButtonBox>
    </Centered>
  )
}
