import React, { createContext, useCallback, useState } from "react"
import { playSound } from "../utils/audio"
import { Notification } from "../../../models/Notification"

export interface NotificationContextType {
  notifications: Notification[]
  addNotification: (notification: Notification) => void
}

export const NotificationContext = createContext<NotificationContextType>(null!)

const notificationTimeOut = 1000

export function NotificationProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [notifications, setNotifications] = useState<Notification[]>([])

  const addNotification = useCallback((notification: Notification) => {
    setNotifications((notifications) => [...notifications, notification])
    setTimeout(() => {
      setNotifications((notifications) =>
        notifications.filter((n) => n !== notification)
      )
    }, notificationTimeOut)
    if (notification.sound) {
      playSound(notification.sound)
    }
  }, [])

  return (
    <NotificationContext.Provider value={{ notifications, addNotification }}>
      {children}
    </NotificationContext.Provider>
  )
}
