import { Socket } from "socket.io-client"
import { ApiErrorCallback } from "../../../models/ApiError"
import { track } from "../utils/tracking"
import {
  CreateGameCallback,
  GameCallback,
  PlayerAPI,
  SceneCallback,
  GetCurrentGameIdCallback,
} from "./Interface"

export class SocketPlayerAPI implements PlayerAPI {
  private gameId?: string

  constructor(private socket: Socket) {}

  subscribeToScene(callback: SceneCallback) {
    this.socket.on("sceneUpdate", callback)
    return () => {
      this.socket.off("sceneUpdate", callback)
    }
  }

  subscribeToGame(callback: GameCallback) {
    this.socket.on("gameUpdate", callback)
    return () => {
      this.socket.off("gameUpdate", callback)
    }
  }

  joinGame(gameId: string, callback: ApiErrorCallback) {
    track("Joined Game", { gameId })

    this.gameId = gameId
    this.socket.emit("joinGame", gameId, callback)
  }

  setPlayerName(playerName: string) {
    track("Set Player Name", { playerName, gameId: this.gameId })

    this.socket.emit("setPlayerName", playerName)
  }

  submitWords(words: string[]) {
    track("Submited Words", { words, gameId: this.gameId })

    this.socket.emit("submitWords", words)
  }

  startDrawingWords() {
    track("Started Drawing Words", { gameId: this.gameId })

    this.socket.emit("startDrawingWords")
  }

  markAsCorrect() {
    track("Marked Word As Correct", { gameId: this.gameId })
    this.socket.emit("markAsCorrect")
  }

  skipWord() {
    track("Skipped Word", { gameId: this.gameId })
    this.socket.emit("skipWord")
  }

  createGame(hostId: string, callback: CreateGameCallback): void {
    this.socket.emit("createGame", hostId, (gameId: string) => {
      track("Created Game", { hostId, gameId })
      callback(gameId)
    })
  }

  getCurrentGameId(callback: GetCurrentGameIdCallback): void {
    this.socket.emit("getCurrentGameId", (gameId: string) => {
      callback(gameId)
    })
  }

  startGame(): void {
    track("Started Game", { gameId: this.gameId })
    this.socket.emit("startGame")
  }

  startInitialRound(): void {
    track("Started Initial Round", { gameId: this.gameId })
    this.socket.emit("startInitialRound")
  }

  startNextRound(): void {
    track("Started Next Round", { gameId: this.gameId })
    this.socket.emit("startNextRound")
  }

  continueRound(): void {
    track("Continued Round", { gameId: this.gameId })
    this.socket.emit("continueRound")
  }

  kickPlayer(playerId: string): void {
    track("Cicked Player", { gameId: this.gameId, playerId })
    this.socket.emit("kickPlayer", playerId)
  }

  startNewSession(): void {
    track("Start New Session", { gameId: this.gameId })
    this.socket.emit("startNewSession")
  }
}
