import { TurnInProgressScene } from "../../../models/PlayerScene"
import { Centered } from "../components/Centered"
import { TeamBox, TeamLabel } from "../components/TeamBox"
import { Timer } from "../components/Timer"

interface Props {
  scene: TurnInProgressScene
}

export const TurnInProgressScreen = ({ scene }: Props) => {
  return (
    <Centered>
      <TeamBox>
        <TeamLabel color={scene.teamColor}>{scene.playerName}</TeamLabel>
        <Timer timeLeft={scene.timeLeft} />
      </TeamBox>
    </Centered>
  )
}
