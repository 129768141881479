import styled from "styled-components"
import { Notification } from "../../../models/Notification"
import { black, colorByName, colorForBackground } from "./colors"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { TopBarHeight } from "./TopBar"

const Container = styled.div`
  position: fixed;
  z-index: 1;
  top: ${TopBarHeight};
  left: 0px;
  right: 0px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: ${black};
`

const BannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 15px;
  margin-bottom: 10px;

  box-shadow: 0px 2px 4px rgba(40, 43, 47, 0.26);
  border-radius: 15px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  // enter from
  &.fade-enter {
    transition: all 50ms ease-in;
    transform: scale(0.8);
  }

  // enter to
  &.fade-enter-active {
    transform: scale(1);
  }

  // exit from
  &.fade-exit {
    transition: all 200ms ease-in;
    opacity: 1;
  }

  // exit to
  &.fade-exit-active {
    opacity: 0;
    transform: scale(0.8);
  }
`

interface OverlayProps {
  notifications: Notification[]
}

interface BannerProps {
  notification: Notification
}

const Banner = ({ notification: { colour, message } }: BannerProps) => {
  return (
    <BannerContainer
      style={{
        background: colorByName[colour],
        color: colorForBackground[colour],
      }}
    >
      {message}
    </BannerContainer>
  )
}

export const NotificationOverlay = ({ notifications }: OverlayProps) => {
  return (
    <Container>
      <TransitionGroup>
        {notifications.map((n, idx) => (
          <CSSTransition
            key={`banner-${n.id}`}
            classNames="fade"
            timeout={300}
            unmountOnExit
          >
            {() => <Banner notification={n} />}
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Container>
  )
}
