import styled, { keyframes } from "styled-components"
import { black, white } from "./colors"

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const slideDown = keyframes`
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`

interface ContainerProps {
  closing?: boolean
}

export const ModalBox = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 15px;

  width: 270px;
  background: ${white};
  color: ${black};

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: left;

  border-radius: 10px;

  animation: ${(props) => (props.closing ? slideDown : slideUp)} 200ms ease-out;
`
