import styled from "styled-components"

const Container = styled.div`
  font-style: italic;
  font-weight: normal;
  font-size: 96px;
  line-height: 114px;
  padding: 10px;
  box-sizing: border-box;
  min-width: 290px;
  text-align: center;
  font-variant-numeric: tabular-nums;
`

export const Timer = ({ timeLeft }: { timeLeft: number }) => (
  <Container>00:{timeLeft.toString().padStart(2, "0")}</Container>
)
