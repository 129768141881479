import styled from "styled-components"
import { grey, blue } from "./colors"

const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px 30px;
  background: ${grey};
  border-radius: 3px;
  overflow: hidden;
  align-self: stretch;
  box-sizing: border-box;
`

const Bar = styled.div`
  height: 5px;
  background: ${blue};
  box-sizing: border-box;
  transition: all 100ms linear;
`

interface ProgressBarProps {
  progress: number
}

export const ProgressBar = ({ progress }: ProgressBarProps) => {
  return (
    <BarContainer>
      <Bar
        style={{
          width: `${progress}%`,
        }}
      />
    </BarContainer>
  )
}
