import styled from "styled-components"

export const Subtitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: center;
`
