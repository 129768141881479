import styled, { keyframes } from "styled-components"
import { semiTransparentOverlay } from "./colors"

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const disappear = keyframes`
  to {
    opacity: 0;
  }
`

interface ContainerProps {
  closing?: boolean
}

export const Overlay = styled.div<ContainerProps>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${semiTransparentOverlay};

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1;

  animation: ${(props) => (props.closing ? disappear : appear)} 200ms ease-out;
`
