import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled, { keyframes } from "styled-components"
import { useAuth } from "../hooks/useAuth"
import { PlayerAPI } from "../PlayerAPI/Interface"
import { track } from "../utils/tracking"
import { darkerWhite } from "./colors"
import { IconButton } from "./IconButton"
import { CloseIcon } from "./icons/CloseIcon"
import { LogInIcon } from "./icons/LogInIcon"
import { LogOutIcon } from "./icons/LogOutIcon"
import { MenuIcon } from "./icons/MenuIcon"
import { StarIcon } from "./icons/StarIcon"
import { Logo } from "./Logo"
import { MenuActionItem } from "./MenuItem"

export const TopBarHeight = "90px"

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;

  > div {
    margin-left: 10px;
  }
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  > div {
    margin-left: 10px;
  }
`

const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  min-height: ${TopBarHeight};
  box-sizing: border-box;
`

const StyledLogo = styled(Logo)`
  cursor: pointer;
`

const slideLeft = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`

const slideRight = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
`

const MenuOverlay = styled.div<{ isClosing: boolean; isOpen: boolean }>`
  background: ${darkerWhite};
  position: fixed;
  top: ${TopBarHeight};
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 800px;
  margin: auto;
  z-index: 1;
  overflow-y: scroll;

  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  align-items: stretch;

  animation: ${(props) => (props.isClosing ? slideRight : slideLeft)} 200ms
    ease-out;
`

const iconAppear = keyframes`
  from {
    opactity: 0;
  }
  to {
    opacity: 1;
  }
`

const iconDisAppear = keyframes`
  from {
    opactity: 1;
  }
  to {
    opacity: 0;
  }
`

const StyledCloseIcon = styled(CloseIcon)<{ isClosing: boolean }>`
  animation: ${(props) => (props.isClosing ? iconDisAppear : iconAppear)} 200ms
    ease-out;
`

const SignItem = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()

  const signOut = () => {
    track("Clicked Sign Out")
    auth.signOut().then(() => navigate("/"))
    onClose()
  }

  const logIn = () => {
    track("Clicked Sign In")
    navigate("/login", { state: { from: location } })
    onClose()
  }

  return (
    <>
      {auth?.user ? (
        <MenuActionItem onClick={signOut}>
          <LogOutIcon />
          Sign Out
        </MenuActionItem>
      ) : (
        <MenuActionItem onClick={logIn}>
          <LogInIcon />
          Sign Up
        </MenuActionItem>
      )}
    </>
  )
}

const MenuItems = ({
  onClose,
  onNewGame,
}: {
  onClose: () => void
  onNewGame: () => void
}) => {
  const createNewGame = () => {
    track("Clicked New Game")
    onNewGame()
    onClose()
  }

  const authEnabled = false

  return (
    <>
      <div id="players-portal" />
      <MenuActionItem onClick={createNewGame}>
        <StarIcon />
        New Game
      </MenuActionItem>
      {authEnabled && <SignItem onClose={onClose} />}
    </>
  )
}

type ModalState = "open" | "closing" | "closed"

interface TopBarProps {
  playerId: string
  api: PlayerAPI
}

export const TopBar = ({ playerId, api }: TopBarProps) => {
  const navigate = useNavigate()
  const [state, setState] = useState<ModalState>("closed")

  const close = () => {
    setState("closing")
    setTimeout(() => {
      setState("closed")
    }, 200)
  }

  const toggle = () => {
    track("Clicked Menu")
    if (state === "open") {
      close()
    } else {
      setState("open")
    }
  }

  const newGame = () => {
    api.createGame(playerId, (gameId) => {
      navigate(`/game/${gameId}`)
    })
  }

  const isMenuOpen = state !== "closed"
  const isMenuClosing = state === "closing"

  return (
    <BarContainer>
      <LeftContainer>
        <StyledLogo onClick={() => navigate("/game")} />
      </LeftContainer>
      <RightContainer>
        <div id="help-portal" />
        <IconButton onClick={toggle}>
          {isMenuOpen ? (
            <StyledCloseIcon isClosing={isMenuClosing} />
          ) : (
            <MenuIcon />
          )}
        </IconButton>
      </RightContainer>
      <MenuOverlay isClosing={isMenuClosing} isOpen={isMenuOpen}>
        <MenuItems onClose={close} onNewGame={newGame} />
      </MenuOverlay>
    </BarContainer>
  )
}
