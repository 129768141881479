import styled from "styled-components"
import { TeamColor } from "../../../models/TeamColors"
import { colorByName, colorForBackground, grey } from "./colors"

interface Props {
  color: TeamColor
}

export const TeamLabel = styled.div<Props>`
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 30px;
  background-color: ${({ color }) => colorByName[color]};
  color: ${({ color }) => colorForBackground[color]};
  padding: 10px 15px;
  min-width: 240px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
`

export const TeamBox = styled.div`
  border: 1px solid ${grey};
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 15px;
`
