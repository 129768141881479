import { useAddToHomescreenPrompt } from "../hooks/useAddToHomescreenPrompt"
import { Button } from "./Button"
import { track } from "../utils/tracking"

const DISABLE_INSTALL = false

export const InstallButton = () => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt()

  const promptAvailable = !!prompt

  const install = () => {
    track("Clicked Install")
    promptToInstall()
  }

  if (DISABLE_INSTALL || !promptAvailable) return null

  return (
    <Button variant="default" onClick={install}>
      Get the App
    </Button>
  )
}
