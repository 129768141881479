import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import { initAudio } from "./utils/audio"
import { initTracking } from "./utils/tracking"
import { initializeApp } from "firebase/app"
import { AuthProvider } from "./contexts/AuthContext"
import { NotificationProvider } from "./contexts/NotificationContext"

initializeApp({
  apiKey: "AIzaSyA-GV0FuQB6dEnyYcbUVyqGw8h3YSMNiEw",
  authDomain: "thehat-f7a53.firebaseapp.com",
  projectId: "thehat-f7a53",
  storageBucket: "thehat-f7a53.appspot.com",
  messagingSenderId: "764299665648",
  appId: "1:764299665648:web:7a7f098730f96e2c7f2235",
  measurementId: "G-XPWQWYEES3",
})

initTracking()

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

serviceWorkerRegistration.register({
  onUpdate(registration) {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" })
    }
    window.location.reload()
  },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

document.addEventListener("click", initAudio, { once: true })
