import { Centered } from "../components/Centered"
import { ErrorScene } from "../../../models/PlayerScene"
import { PlayerAPI } from "../PlayerAPI/Interface"
import { Flag } from "../components/Flag"
import { Title } from "../components/Title"

interface Props {
  scene: ErrorScene
  api: PlayerAPI
}

export const ErrorScreen = ({
  scene: {
    error: { message },
  },
}: Props) => {
  return (
    <Centered>
      <Flag>🙊</Flag>
      <Title>{message}</Title>
    </Centered>
  )
}
