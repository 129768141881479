export const primaryDimmed = "#3E436C"
export const black = "#282B2F"
export const dimmedBlack = "#5F6266"
export const lightGrey = "#F6F6F6"
export const grey = "#DFDFDF"
export const white = "#FFFFFF"
export const darkerWhite = "#FAFAFA"
export const pink = "#E36488"
export const blue = "#70A1D7"
export const green = "#6FCF97"
export const yellow = "#FFC93C"
export const red = "#E66B66"
export const semiTransparentOverlay = "rgba(141, 141, 141, 0.5)"

export const colorByName = {
  neutral: lightGrey,
  red,
  green,
  blue,
  black,
  pink,
  yellow,
}

export const colorForBackground = {
  neutral: black,
  pink: white,
  blue: white,
  green: white,
  yellow: black,
  red: white,
}
