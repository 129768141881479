import { Centered } from "../components/Centered"
import { EndGameScene } from "../../../models/PlayerScene"
import { ScoreBoard } from "../components/ScoreBoard"
import { Title } from "../components/Title"
import { Button } from "../components/Button"
import { PlayerAPI } from "../PlayerAPI/Interface"
import { Flag } from "../components/Flag"
import { InstallButton } from "../components/InstallButton"

interface Props {
  api: PlayerAPI
  scene: EndGameScene
}

export const EndGameScreen = ({ scene, api }: Props) => {
  const startNewGame = () => {
    api.startNewSession()
  }

  return (
    <Centered>
      <Flag>🏁</Flag>
      <Title>Game Completed!</Title>
      {scene.isHost ? (
        <Button variant="primary" onClick={startNewGame}>
          Start New Game
        </Button>
      ) : (
        <InstallButton />
      )}
      <ScoreBoard label="Team Rating" teamScores={scene.teamScores} />
      <ScoreBoard label="Player Rating" teamScores={scene.playerScores} />
    </Centered>
  )
}
