import { useEffect, useState } from "react"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { ModalBox } from "../ModalBox"
import { Overlay } from "../Overlay"
import ReactDOM from "react-dom"
import { IconButton } from "../IconButton"
import { HelpIcon } from "../icons/HelpIcons"
import styled from "styled-components"

interface Props {
  children: React.ReactNode
  onBoardingId?: string
}

type HelpState = "open" | "closing" | "closed"

const StyledModalBox = styled(ModalBox)`
  align-items: flex-start;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  p:not(:last-child) {
    margin-bottom: 9px;
  }
`

export const Help = ({ children, onBoardingId }: Props) => {
  const [seenBefore, setSeenBefore] = useLocalStorage<boolean>(
    `onboarding-${onBoardingId}-seen`,
    false
  )
  const [state, setState] = useState<HelpState>(
    onBoardingId && !seenBefore ? "open" : "closed"
  )

  useEffect(() => {
    if (state === "open" && !seenBefore && onBoardingId) {
      setSeenBefore(true)
    }
  }, [onBoardingId, seenBefore, setSeenBefore, state])

  const close = () => {
    setState("closing")
    setTimeout(() => {
      setState("closed")
    }, 200)
  }

  const toggle = () => {
    if (state === "open") {
      close()
    } else {
      setState("open")
    }
  }

  const isActive = state !== "closed"
  const isClosing = state === "closing"

  const helpPortal = document.getElementById("help-portal")
  if (!helpPortal) return null

  return (
    <>
      {ReactDOM.createPortal(
        <>
          <IconButton onClick={toggle}>
            <HelpIcon />
          </IconButton>
          {isActive && (
            <Overlay closing={isClosing} onClick={close}>
              <StyledModalBox closing={isClosing}>{children}</StyledModalBox>
            </Overlay>
          )}
        </>,
        helpPortal
      )}
    </>
  )
}
