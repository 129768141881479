import { Centered } from "../components/Centered"
import { playerLink } from "../config"
import QRCode from "qrcode.react"
import { NewGameScene } from "../../../models/PlayerScene"
import styled from "styled-components"
import { Button, ButtonBox } from "../components/Button"
import { PlayerAPI } from "../PlayerAPI/Interface"
import { track } from "../utils/tracking"
import { Subtitle } from "../components/Subtitle"
import { Help } from "../components/Help/Help"
import { GameInfo } from "../../../models/GameInfo"
import { useNotifications } from "../hooks/useNotifications"
import { darkerWhite } from "../components/colors"
import { Lobby } from "./Lobby"

const QRCodeContainer = styled.div`
  margin-bottom: 15px;
  margin-top: 5px;
`

const Title = styled(Subtitle)`
  max-width: 256px;
`

interface NewGameSceneProps {
  api: PlayerAPI
  scene: NewGameScene
  gameInfo?: GameInfo
}

export const NewGameScreen = ({
  scene: { gameId },
  api,
  gameInfo,
}: NewGameSceneProps) => {
  const canStartTheGame = gameInfo && gameInfo.players.length > 1
  const link = playerLink(gameId)
  const { addNotification } = useNotifications()

  const onCopyLink = (e: any) => {
    e.stopPropagation()
    track("Clicked Share Link")
    navigator.clipboard.writeText(link)
    addNotification({
      colour: "neutral",
      id: "link-copied",
      message: "Game link copied to clipboard!",
    })
  }

  return (
    <Centered>
      <Title onClick={() => navigator.clipboard.writeText(link)}>
        Share this QR code to invite players
      </Title>

      <QRCodeContainer>
        <QRCode value={link} size={256} bgColor={darkerWhite} />
      </QRCodeContainer>

      <ButtonBox>
        <Button
          disabled={!canStartTheGame}
          onClick={() => api.startGame()}
          variant="primary"
        >
          Start Playing
        </Button>

        <Button variant="default" onClick={onCopyLink}>
          Share Link
        </Button>
      </ButtonBox>

      {gameInfo && <Lobby api={api} gameInfo={gameInfo} />}

      <Help onBoardingId="join-with-qr">
        <p>Congrats, you've just started a new game!</p>
        <p>
          Now it's time to invite other players. Just ask them to scan this QR
          code or send them a link to this game.
        </p>
        <p>You can start playing once everyone in.</p>
      </Help>
    </Centered>
  )
}
