import styled from "styled-components"

export const Title = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  padding: 10px 15px;
  margin-bottom: 10px;
  text-align: center;
`
