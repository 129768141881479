import { Scene, PlayerSceneNames } from "../../../models/PlayerScene"
import { PlayerAPI } from "../PlayerAPI/Interface"
import { DrawWordsScreen } from "./DrawWordsScreen"
import { EnterWordsScreen } from "./EnterWordsScreen"
import { PlayerIntroScreen } from "./PlayerIntroScreen"
import { WaitingScreen } from "./WaitingScreen"
import { WelcomeToTeamScreen } from "./WelcomeToTeamScreen"
import { CurrentTurnScreen } from "./CurrentTurnScreen"
import { EndGameScreen } from "./EndGameScreen"
import { RoundEndScreen } from "./RoundEndScreen"
import { NewGameScreen } from "./NewGameScreen"
import { TurnInProgressScreen } from "./TurnInProgressScreen"
import { RoundStartScreen } from "./RoundStartScreen"
import { TurnEndScreen } from "./TurnEndScreen"
import { useEffect } from "react"
import { track } from "../utils/tracking"
import { UserInfo } from "../../../models/UserInfo"
import { GameInfo } from "../../../models/GameInfo"
import { GameRedirectScreen } from "./GameRedirectScreen"
import { ErrorScreen } from "./ErrorScreen"

const screens: { [key in PlayerSceneNames]: React.ElementType } = {
  PlayerIntro: PlayerIntroScreen,
  DrawWords: DrawWordsScreen,
  EnterWords: EnterWordsScreen,
  Waiting: WaitingScreen,
  Error: ErrorScreen,
  WelcomeToTeam: WelcomeToTeamScreen,
  CurrentTurn: CurrentTurnScreen,
  EndGame: EndGameScreen,
  RoundEnd: RoundEndScreen,
  NewGame: NewGameScreen,
  TurnInProgress: TurnInProgressScreen,
  RoundStart: RoundStartScreen,
  TurnEnd: TurnEndScreen,
  GameRedirect: GameRedirectScreen,
}

interface Props {
  scene: Scene
  api: PlayerAPI
  gameId?: string
  userInfo?: UserInfo | null
  gameInfo?: GameInfo | null
}

export const PlayerScreen = ({
  scene,
  api,
  gameId,
  userInfo,
  gameInfo,
}: Props) => {
  const Screen = screens[scene.sceneName]

  useEffect(
    () =>
      track(`Screen: ${scene.sceneName}`, {
        gameId,
      }),
    [scene.sceneName, gameId]
  )

  return (
    <Screen scene={scene} api={api} userInfo={userInfo} gameInfo={gameInfo} />
  )
}
