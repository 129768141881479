import styled from "styled-components"

export const IconButton = styled.div`
  width: 50px;
  height: 50px;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
`
