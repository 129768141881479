import styled from "styled-components"
import {
  blue,
  dimmedBlack,
  green,
  grey,
  primaryDimmed,
  red,
  white,
} from "./colors"

type ButtonVariants = "primary" | "default" | "green" | "red"

interface ButtonProps {
  variant: ButtonVariants
  fullWidth?: boolean
}

const backgrounds = {
  primary: blue,
  default: "transparent",
  green,
  red,
}

const colors = {
  primary: white,
  default: primaryDimmed,
  green: white,
  red: white,
}

const disabledColors = {
  primary: white,
  default: dimmedBlack,
  green: white,
  red: grey,
}

const borders = {
  primary: "none",
  default: `2px solid ${primaryDimmed}`,
  green: "none",
  red: "none",
}

const disabledBackgrounds = {
  primary: grey,
  default: "transparent",
  green,
  red,
}

export const ButtonBox = styled.div`
  padding: 15px 0px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

export const ResponsiveButtonBox = styled.div`
  padding: 15px 0px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

export const Button = styled.button<ButtonProps>`
  appearance: none;
  outline: none;

  border-radius: 25px;
  min-width: ${(props) => (props.fullWidth ? "100%" : "240px")};
  height: 48px;
  margin: 5px;

  padding: 0px 25px;

  transition: all 100ms ease-in-out;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:active:not(:disabled) {
    transform: scale(0.97);
  }

  &:hover:not(:disabled):not(:active) {
    transform: scale(1.03);
  }

  /* text-transform: uppercase; */
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  background: ${(props) =>
    props.disabled
      ? disabledBackgrounds[props.variant]
      : backgrounds[props.variant]};

  color: ${(props) =>
    props.disabled ? disabledColors[props.variant] : colors[props.variant]};

  border: ${(props) => borders[props.variant]};
`
