import styled, { keyframes } from "styled-components"
import { Score } from "../../../models/PlayerScene"
import { TeamColor } from "../../../models/TeamColors"
import { black, colorByName, colorForBackground } from "./colors"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
`

const pop = keyframes`
  from {
    transform: scale(0.95);
  }

  to {
    transform: scale(1);
  }
`

const Box = styled.div<{ color: TeamColor }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;

  min-width: 300px;
  box-sizing: border-box;

  font-size: 20px;
  font-weight: 600;
  line-height: 24px;

  background-color: ${({ color }) => colorByName[color]};
  color: ${({ color }) => colorForBackground[color]};
  border: 2px solid ${black};

  animation: ${pop} 100ms ease-in;
`

const Place = styled.div`
  min-width: 42px;
`

const Points = styled.div`
  min-width: 42px;
  display: flex;
  justify-content: flex-end;
`

export const TeamLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 5px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
`

function formatPlace(place: number): string {
  if (place === 1) return `${place}st`
  if (place === 2) return `${place}nd`
  if (place === 3) return `${place}rd`
  return `${place}th`
}

interface ScoreBoardProps {
  teamScores: Score[]
  label?: string
}

export const ScoreBoard = ({ teamScores, label }: ScoreBoardProps) => {
  return (
    <Container>
      {label && <Title>{label}</Title>}
      {teamScores.map((score, idx) => (
        <Box color={score.color} key={`score-${score.name}`}>
          <Place>{formatPlace(idx + 1)}</Place>
          <TeamLabel color={score.color}>{score.name}</TeamLabel>
          <Points>{score.points}</Points>
        </Box>
      ))}
    </Container>
  )
}
