import ReactDOM from "react-dom"
import { UsersIcon } from "../icons/Users"
import { PlayerInfo } from "../../../../models/PlayerInfo"
import { PlayerList } from "./PlayersList"
import { PlayerAPI } from "../../PlayerAPI/Interface"
import { MenuItem } from "../MenuItem"

interface Props {
  players: PlayerInfo[]
  enableAdmin?: boolean
  api: PlayerAPI
}

export const PlayersButton = ({ players, enableAdmin, api }: Props) => {
  const playersPortal = document.getElementById("players-portal")
  if (!playersPortal) return null

  if (!players.length) return null

  return (
    <>
      {ReactDOM.createPortal(
        <>
          <MenuItem>
            <UsersIcon />
            Players
          </MenuItem>
          <PlayerList
            players={players ?? []}
            enableAdmin={enableAdmin}
            api={api}
          />
        </>,
        playersPortal
      )}
    </>
  )
}
