import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { GameRedirect } from "../../../models/PlayerScene"
import { Centered } from "../components/Centered"
import { Spinner } from "../components/Spinner"
import { track } from "../utils/tracking"

interface Props {
  scene: GameRedirect
}

export const GameRedirectScreen = ({ scene }: Props) => {
  const navigate = useNavigate()

  useEffect(() => track("Screen: GameRedirect"), [])

  useEffect(() => {
    navigate(`/game/${scene.newGameId}`)
  })

  return (
    <Centered>
      <Spinner />
    </Centered>
  )
}
