import { Centered } from "../components/Centered"
import { RoundEndScene } from "../../../models/PlayerScene"
import { ScoreBoard } from "../components/ScoreBoard"
import { Button, ButtonBox } from "../components/Button"
import { PlayerAPI } from "../PlayerAPI/Interface"
import { Title } from "../components/Title"
import { Flag } from "../components/Flag"

interface Props {
  scene: RoundEndScene
  api: PlayerAPI
}

export const RoundEndScreen = ({ scene, api }: Props) => {
  return (
    <Centered>
      <Flag>👏</Flag>
      <Title>Round #{scene.roundNumber + 1} Complete!</Title>
      {scene.isHost && (
        <ButtonBox>
          <Button onClick={() => api.startNextRound()} variant="primary">
            Continue
          </Button>
        </ButtonBox>
      )}
      <ScoreBoard label="Team Rating" teamScores={scene.teamScores} />
      <ScoreBoard label="Player Rating" teamScores={scene.playerScores} />
    </Centered>
  )
}
