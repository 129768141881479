import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut as authSignOut,
} from "firebase/auth"
import React, { useEffect, useState } from "react"
import { UserInfo } from "../../../models/UserInfo"
import { identify, track } from "../utils/tracking"

export interface AuthContextType {
  user: UserInfo | null
  loading: boolean
  signIn: () => Promise<UserInfo>
  signOut: () => Promise<void>
}

export const AuthContext = React.createContext<AuthContextType>(null!)

async function signInWithGoogle(): Promise<UserInfo> {
  const provider = new GoogleAuthProvider()
  const auth = getAuth()
  const result = await signInWithPopup(auth, provider)
  return result.user
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<UserInfo | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    return onAuthStateChanged(getAuth(), (user) => {
      console.log("auth state changed", user)
      setUser(user)
      setLoading(false)
      if (user) {
        identify(user)
      }
    })
  }, [])

  const signIn = () => {
    track("Signed In")
    return signInWithGoogle()
  }

  const signOut = () => {
    track("Signed Out")
    return authSignOut(getAuth())
  }

  let value = { user, loading, signIn, signOut }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
