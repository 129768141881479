import styled from "styled-components"
import { lightGrey } from "./colors"

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;

  > svg {
    margin-right: 10px;
  }
`

export const MenuActionItem = styled(MenuItem)`
  cursor: pointer;

  &:hover {
    background: ${lightGrey};
  }
`
