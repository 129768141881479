import { useEffect, useState } from "react"

export function useWakeLock() {
  const [wakeLock, setWakeLock] = useState<null | WakeLockSentinel>(null)

  useEffect(() => {
    if (navigator["wakeLock"] && !wakeLock) {
      console.log("requesting wake lock")
      navigator.wakeLock
        .request("screen")
        .then((lock) => {
          console.log("wake lock acquired", lock)
          setWakeLock(lock)
        })
        .catch(() => {
          console.log("screen lock is not available")
        })
    }

    return () => {
      if (wakeLock) {
        wakeLock
          .release()
          .then(() => console.log("screen lock released"))
          .catch(console.error)
      }
    }
  }, [wakeLock])
}
