import { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Button, ButtonBox } from "../components/Button"
import { Centered } from "../components/Centered"
import { Subtitle } from "../components/Subtitle"
import { useAuth } from "../hooks/useAuth"
import { track } from "../utils/tracking"

type LocationState = { from: Location } | undefined

export function Login() {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const from = (location.state as LocationState)?.from?.pathname

  useEffect(() => track("Screen: Login"), [])

  const toggleSignIn = () => {
    return auth.user
      ? auth.signOut()
      : auth.signIn().then(() => {
          if (from) {
            navigate(from, { replace: true })
          }
        })
  }

  return (
    <Centered>
      {from && <Subtitle>Liked the game?</Subtitle>}
      <ButtonBox>
        <Button
          disabled={auth.loading}
          onClick={toggleSignIn}
          variant="primary"
        >
          {auth.user ? "Sign Out" : "Sign up for updates"}
        </Button>
      </ButtonBox>
    </Centered>
  )
}
