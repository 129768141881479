export const StarIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.8333 20.1459C45.7018 19.7648 45.4625 19.4301 45.1444 19.1824C44.8264 18.9346 44.4433 18.7844 44.0417 18.7501L32.1875 17.0209L26.875 6.25006C26.7044 5.89783 26.438 5.60077 26.1064 5.39292C25.7748 5.18507 25.3914 5.07483 25 5.07483C24.6086 5.07483 24.2252 5.18507 23.8936 5.39292C23.5619 5.60077 23.2956 5.89783 23.125 6.25006L17.8125 17.0001L5.95833 18.7501C5.57275 18.8049 5.21025 18.9666 4.91197 19.2171C4.61369 19.4675 4.39158 19.7965 4.27083 20.1667C4.1603 20.5285 4.15038 20.9136 4.24213 21.2807C4.33389 21.6477 4.52386 21.9828 4.79166 22.2501L13.3958 30.5834L11.3125 42.4167C11.2381 42.8073 11.2771 43.211 11.4247 43.5802C11.5724 43.9493 11.8226 44.2685 12.1458 44.5001C12.4609 44.7253 12.8324 44.8582 13.2188 44.884C13.6052 44.9098 13.9912 44.8273 14.3333 44.6459L25 39.0834L35.625 44.6667C35.9174 44.8317 36.2476 44.9178 36.5833 44.9167C37.0246 44.9183 37.455 44.7797 37.8125 44.5209C38.1357 44.2894 38.3859 43.9702 38.5336 43.601C38.6813 43.2318 38.7202 42.8281 38.6458 42.4376L36.5625 30.6042L45.1667 22.2709C45.4675 22.0161 45.6898 21.6811 45.8078 21.305C45.9258 20.9288 45.9346 20.5269 45.8333 20.1459ZM33.0208 28.4792C32.7765 28.7155 32.5937 29.0081 32.4884 29.3313C32.3831 29.6544 32.3585 29.9985 32.4167 30.3334L33.9167 39.0626L26.0833 34.8959C25.7819 34.7354 25.4456 34.6514 25.1042 34.6514C24.7627 34.6514 24.4264 34.7354 24.125 34.8959L16.2917 39.0626L17.7917 30.3334C17.8499 29.9985 17.8252 29.6544 17.7199 29.3313C17.6146 29.0081 17.4318 28.7155 17.1875 28.4792L10.9375 22.2292L19.7083 20.9584C20.0458 20.9114 20.3667 20.7824 20.6427 20.5827C20.9187 20.3829 21.1416 20.1185 21.2917 19.8126L25 11.8751L28.9167 19.8334C29.0667 20.1393 29.2896 20.4037 29.5656 20.6035C29.8417 20.8033 30.1625 20.9323 30.5 20.9792L39.2708 22.2501L33.0208 28.4792Z"
        fill="#282B2F"
      />
    </svg>
  )
}
