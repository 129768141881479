import { PlayerAPI } from "../PlayerAPI/Interface"
import { GameInfo } from "../../../models/GameInfo"
import { PlayerList } from "../components/PlayersList/PlayersList"
import { Subtitle } from "../components/Subtitle"
import styled from "styled-components"

interface LobbyProps {
  api: PlayerAPI
  gameInfo: GameInfo
}

const Title = styled(Subtitle)`
  margin-top: 15px;
`

export const Lobby = ({ api, gameInfo }: LobbyProps) => {
  return (
    <>
      <Title>Players</Title>
      <PlayerList players={gameInfo.players} api={api} />
    </>
  )
}
