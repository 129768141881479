import styled from "styled-components"
import { Centered } from "../components/Centered"
import { CurrentTurnScene } from "../../../models/PlayerScene"
import { PlayerAPI } from "../PlayerAPI/Interface"
import { Button, ButtonBox } from "../components/Button"
import { TeamBox, TeamLabel } from "../components/TeamBox"

interface Props {
  scene: CurrentTurnScene
  api: PlayerAPI
}

const CurrentTurn = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
`

const TeamMate = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 400;
  position: relative;

  &[data-is-active="true"] {
    font-weight: bold;
    ::before {
      content: "➔";
      left: -1.5rem;
      position: absolute;
    }
  }
`

export const CurrentTurnScreen = ({
  scene: { playerName, teamMates, teamColor, teamName, isActive },
  api,
}: Props) => {
  return (
    <Centered>
      <CurrentTurn>next turn</CurrentTurn>
      <TeamBox>
        <TeamLabel color={teamColor}>{teamName}</TeamLabel>
        {teamMates.map((name, index) => (
          <TeamMate
            key={`team-mate-${index}`}
            data-is-active={name === playerName}
          >
            {name}
          </TeamMate>
        ))}
      </TeamBox>
      {isActive && (
        <ButtonBox>
          <Button onClick={() => api.startDrawingWords()} variant="primary">
            Ready
          </Button>
        </ButtonBox>
      )}
    </Centered>
  )
}
