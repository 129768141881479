import styled from "styled-components"
import { RoundStartScene } from "../../../models/PlayerScene"
import { Button, ButtonBox } from "../components/Button"
import { Centered } from "../components/Centered"
import { Title } from "../components/Title"
import { PlayerAPI } from "../PlayerAPI/Interface"

interface Props {
  scene: RoundStartScene
  api: PlayerAPI
}

const Rules = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 15px;
`

export const RoundStartScreen = ({
  scene: { roundNumber, rules, isHost },
  api,
}: Props) => {
  return (
    <Centered>
      <Title>Round #{roundNumber + 1}</Title>
      <Rules>{rules}</Rules>
      {isHost && (
        <ButtonBox>
          <Button variant="primary" onClick={() => api.continueRound()}>
            Continue
          </Button>
        </ButtonBox>
      )}
    </Centered>
  )
}
