import { Centered } from "../components/Centered"
import { TurnEndScene } from "../../../models/PlayerScene"
import styled from "styled-components"
import { ScoreBoard } from "../components/ScoreBoard"
import { PlayerAPI } from "../PlayerAPI/Interface"
import { green, red } from "../components/colors"
import { Title } from "../components/Title"
import { Flag } from "../components/Flag"
import { ProgressBar } from "../components/ProgressBar"

interface Props {
  scene: TurnEndScene
  api: PlayerAPI
}

const WordsRow = styled.div`
  display: flex;
  flex-direction: row;

  > :not(:last-child) {
    margin-right: 15px;
  }

  margin-bottom: 10px;
`

const WordCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80px;
`

const WordTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 5px;
`

const WordNumber = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
`

const WordStat = ({ title, value }: { title: string; value: number }) => (
  <WordCol>
    <WordTitle>{title}</WordTitle>
    <WordNumber>{value}</WordNumber>
  </WordCol>
)

const Points = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
`

export const TurnEndScreen = ({ scene }: Props) => {
  const { pointsEarned, countDownProgress } = scene
  const pointsSign = pointsEarned > 0 ? "+" : ""
  const pointsColor = pointsEarned > 0 ? green : red

  return (
    <Centered>
      <ProgressBar progress={countDownProgress} />
      <Flag>⏰</Flag>
      <Title>Time is UP!</Title>
      <WordsRow>
        <WordStat title="Correct" value={scene.correctWords} />
        <WordStat title="Skipped" value={scene.skippedWords} />
        <WordStat title="Left" value={scene.wordsLeft} />
      </WordsRow>
      <Points style={{ color: pointsColor }}>
        {pointsSign}
        {scene.pointsEarned} points
      </Points>
      <ScoreBoard label="Team Rating" teamScores={scene.teamScores} />
      <ScoreBoard label="Player Rating" teamScores={scene.playerScores} />
    </Centered>
  )
}
