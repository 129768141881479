import { PlayerInfo } from "../../../../models/PlayerInfo"
import { green, grey } from "../colors"
import { CheckIcon } from "../icons/CheckIcon"
import styled, { keyframes } from "styled-components"
import { ArrowIcon } from "../icons/ArrowIcon"
import { useState } from "react"
import { Button } from "../Button"
import { PlayerAPI } from "../../PlayerAPI/Interface"

const pop = keyframes`
  from {
    transform: scale(0.95);
  }

  to {
    transform: scale(1);
  }
`

const expand = keyframes`
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px 15px;
  align-self: stretch;

  box-sizing: border-box;

  animation: ${pop} 100ms ease-in;
`

const ItemHeader = styled.div<{ expandable: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;

  cursor: ${(props) => (props.expandable ? "pointer" : "inherit")};
`

const ItemBody = styled.div`
  margin-top: 20px;
  animation: ${expand} 100ms ease-out;
`

const Name = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  align-self: stretch;
  flex-grow: 1;

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  > div:not(:last-child) {
    margin-bottom: 10px;
  }
`

const Icons = styled.div`
  display: flex;
  flex-direction: row;
`

const ArrowButton = styled(ArrowIcon)`
  transform: rotate(${(props) => (props.expanded ? "180deg" : "0deg")});
  transition: all 250ms ease-out;
`

interface ItemProps {
  player: PlayerInfo
  enableAdmin?: boolean
  onKickPlayer: (playerId: string) => void
}

const PlayerItem = ({ player, enableAdmin, onKickPlayer }: ItemProps) => {
  const [expanded, setExpanded] = useState(false)

  const toggle = (e: any) => {
    if (!enableAdmin) return
    e.stopPropagation()
    setExpanded(!expanded)
  }

  const kickPlayer = (e: any) => {
    if (!enableAdmin) return
    e.stopPropagation()
    onKickPlayer(player.id)
  }

  return (
    <ItemContainer>
      <ItemHeader expandable={!!enableAdmin} onClick={toggle}>
        <Name>{player.name}</Name>
        <Icons>
          <CheckIcon color={player.ready ? green : grey} />
          {enableAdmin && <ArrowButton expanded={expanded} />}
        </Icons>
      </ItemHeader>
      {expanded && (
        <ItemBody>
          <Button onClick={kickPlayer} variant="red" fullWidth>
            Remove
          </Button>
        </ItemBody>
      )}
    </ItemContainer>
  )
}

interface ListProps {
  players: PlayerInfo[]
  enableAdmin?: boolean
  api: PlayerAPI
}

export const PlayerList = ({ players, enableAdmin, api }: ListProps) => {
  return (
    <ItemsContainer>
      {players.map((player, idx) => (
        <PlayerItem
          key={`${idx}-${player.name}`}
          player={player}
          enableAdmin={enableAdmin}
          onKickPlayer={(playerId) => api.kickPlayer(playerId)}
        />
      ))}
    </ItemsContainer>
  )
}
