import { nanoid } from "nanoid"

export const SOCKET_URL =
  process.env.NODE_ENV === "production"
    ? `wss://${document.location.hostname}`
    : "ws://localhost:9999"

const urlParams = new URLSearchParams(window.location.search)

export function getPlayerId() {
  const override = urlParams.get("playerId")
  if (override) {
    return override
  }

  let playerId = localStorage.getItem("playerId")
  if (!playerId) {
    playerId = nanoid()
    localStorage.setItem("playerId", playerId)
  }
  return playerId
}

export const gameLink = (gameId: string) =>
  `${document.location.origin}/game/${gameId}`

export const playerLink = gameLink
