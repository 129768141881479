import styled, { keyframes } from "styled-components"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(350deg);
  }
`
const Container = styled.div`
  font-size: 64px;
  margin-bottom: 15px;
  animation: ${rotate} 10s linear infinite;
`

export const Spinner = () => <Container>⏳</Container>
