export const MenuIcon = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 25H43" stroke="#282B2F" strokeWidth="4" strokeLinecap="round" />
    <path d="M6 15H43" stroke="#282B2F" strokeWidth="4" strokeLinecap="round" />
    <path d="M6 35H43" stroke="#282B2F" strokeWidth="4" strokeLinecap="round" />
  </svg>
)
