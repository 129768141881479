import { Centered } from "../components/Centered"
import { WelcomeToTeamScene } from "../../../models/PlayerScene"
import { PlayerAPI } from "../PlayerAPI/Interface"
import styled from "styled-components"
import { TeamBox, TeamLabel } from "../components/TeamBox"
import { Button, ButtonBox } from "../components/Button"
import { Help } from "../components/Help/Help"

interface Props {
  scene: WelcomeToTeamScene
  api: PlayerAPI
}

const Welcome = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
`

const TeamMate = styled.div`
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
  font-weight: 500;
`

export const WelcomeToTeamScreen = ({ scene, api }: Props) => {
  return (
    <Centered>
      <Welcome>welcome to</Welcome>
      <TeamBox>
        <TeamLabel color={scene.teamColor}>{scene.teamName}</TeamLabel>
        {scene.teamMates.map((name, index) => (
          <TeamMate key={`team-mate-${index}`}>{name}</TeamMate>
        ))}
      </TeamBox>
      {scene.isHost && (
        <ButtonBox>
          <Button onClick={() => api.startInitialRound()} variant="primary">
            Continue
          </Button>
        </ButtonBox>
      )}
      <Help>
        <p>Your team has two goals: win the game and have fun doing so!</p>
        <p>Teams will be taking turns guessing the word.</p>
        <p>
          Each turn, a new champion is selected to explain the words to their
          teammates.
        </p>
      </Help>
    </Centered>
  )
}
