import { useState } from "react"
import styled from "styled-components"
import { Button, ButtonBox } from "../components/Button"
import { Form } from "../components/Form"
import { Input } from "../components/Input"
import { EnterWordsScene } from "../../../models/PlayerScene"
import { PlayerAPI } from "../PlayerAPI/Interface"
import { Help } from "../components/Help/Help"

interface Props {
  scene: EnterWordsScene
  api: PlayerAPI
}

const Subtitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 15px;
`

export const EnterWordsScreen = ({ scene, api }: Props) => {
  const [words, setWords] = useState<string[]>(
    new Array(scene.wordCount).fill("")
  )

  const isValid = words.every((word) => word.length > 0)

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        api.submitWords(words)
      }}
    >
      <Subtitle>Enter {scene.wordCount} words</Subtitle>
      {words.map((word, index) => (
        <Input
          key={`word-${index}`}
          type="text"
          value={word}
          onChange={(e) => {
            const newWords = words.slice()
            newWords[index] = e.target.value
            setWords(newWords)
          }}
          required
        />
      ))}
      <ButtonBox>
        <Button disabled={!isValid} variant="primary">
          Submit
        </Button>
      </ButtonBox>
      <Help onBoardingId="submit-words">
        <p>
          This game is about guessing and expressing words. Words are collected
          from all players, mixed together and used throughout the game.
        </p>
        <p>Keep these words in secret 🤫 And get creative!</p>
      </Help>
    </Form>
  )
}
