import styled from "styled-components"
import { grey, white } from "./colors"

export const Input = styled.input`
  appearance: none;
  outline: none;
  padding: 10px 15px;
  width: 240px;
  height: 48px;
  margin-bottom: 15px;
  box-sizing: border-box;

  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  background: ${white};

  border-radius: 10px;
  border: 1px solid ${grey};

  &:focus {
    box-shadow: 0px 0px 1px 1px ${grey};
  }
`
