import { io } from "socket.io-client"
import { SocketPlayerAPI } from "./PlayerAPI/Implementation"
import { getPlayerId, SOCKET_URL } from "./config"
import { PlayerSession } from "./PlayerSession"
import { useEffect, useState } from "react"
import { useNotifications } from "./hooks/useNotifications"
import { NotificationOverlay } from "./components/NotificationsOverlay"
import { GameSelector } from "./components/GameSelector"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { Landing } from "./screens/Landing"
import { Login } from "./screens/Login"
import { TopBar } from "./components/TopBar"
import { Notification } from "../../models/Notification"
import { use100vh } from "react-div-100vh"
import { AppContainer, ContentContainer } from "./components/AppContainer"

const playerId = getPlayerId()

const socket = io(SOCKET_URL, {
  auth: {
    playerId,
  },
})

const playerAPI = new SocketPlayerAPI(socket)

const PlayerApp = ({ connected }: { connected: boolean }) => {
  const height = use100vh()

  return (
    <AppContainer style={height ? { minHeight: height } : {}}>
      <TopBar api={playerAPI} playerId={playerId} />
      <ContentContainer>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/game/:gameId"
            element={
              <PlayerSession
                connected={connected}
                api={playerAPI}
                playerId={playerId}
              />
            }
          ></Route>
          <Route
            path="/game"
            element={<GameSelector playerId={playerId} api={playerAPI} />}
          />
        </Routes>
      </ContentContainer>
    </AppContainer>
  )
}

const connecting: Notification = {
  colour: "neutral",
  id: "connecting",
  message: "Connecting...",
}

function App() {
  const [connected, setConnected] = useState(socket.connected)
  const { notifications, addNotification } = useNotifications()

  useEffect(() => {
    socket.on("notification", addNotification)
    return () => {
      socket.off("notification", addNotification)
    }
  }, [addNotification])

  useEffect(() => {
    socket.on("connect", () => setConnected(true))
    socket.on("disconnect", () => setConnected(false))
  }, [])

  return (
    <Router>
      <NotificationOverlay
        notifications={
          connected ? notifications : [connecting, ...notifications]
        }
      />
      <PlayerApp connected={connected} />
    </Router>
  )
}

export default App
