export const CheckIcon = ({ color = "#6FCF97" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7 7.20002C18.3 6.80002 17.7 6.80002 17.3 7.20002L9.8 14.7L6.7 11.6C6.3 11.2 5.7 11.2 5.3 11.6C4.9 12 4.9 12.6 5.3 13L9.1 16.8C9.3 17 9.5 17.1 9.8 17.1C10.1 17.1 10.3 17 10.5 16.8L18.7 8.60002C19.1 8.20002 19.1 7.60002 18.7 7.20002Z"
        fill={color}
      />
    </svg>
  )
}
