import { PlayerAPI } from "../PlayerAPI/Interface"
import { useEffect } from "react"
import { track } from "../utils/tracking"
import { useNavigate } from "react-router-dom"
import { Centered } from "./Centered"
import { Spinner } from "./Spinner"

interface Props {
  api: PlayerAPI
  playerId: string
}

export const GameSelector = ({ api, playerId }: Props) => {
  const navigate = useNavigate()

  useEffect(() => track("Screen: Game Home"), [])

  useEffect(() => {
    console.log("game selector")
    api.getCurrentGameId((currentGameId) => {
      console.log({ currentGameId })
      if (currentGameId) {
        navigate(`/game/${currentGameId}`, { replace: true })
      } else {
        api.createGame(playerId, (gameId) => {
          navigate(`/game/${gameId}`, { replace: true })
        })
      }
    })
  }, [api, playerId, navigate])

  return (
    <Centered>
      <Spinner />
    </Centered>
  )
}
