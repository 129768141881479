import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Button, ResponsiveButtonBox } from "../components/Button"
import { InstallButton } from "../components/InstallButton"
import { track } from "../utils/tracking"

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0px;

  > section:not(:last-child) {
    margin-bottom: 30px;
  }
`

const MainTitle = styled.section`
  p {
    margin: 0;
    margin-top: 15px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 106%;
  }
`

const MainSubtitle = styled.section`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
`

const CTA = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 40px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 15px;
    margin-top: 0px;
  }

  p {
    margin-top: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
  }
`

export const Landing = () => {
  const navigate = useNavigate()

  useEffect(() => track("Screen: Landing"), [])

  const hostGame = () => {
    track("Clicked Play Now")
    navigate("/game")
  }

  return (
    <Container>
      <HeroSection>
        <MainTitle>
          <p>Classical party game.</p>
          <p>Digitally augmented.</p>
        </MainTitle>
        <MainSubtitle>
          Have fun and bond over word guessing games. Play in no time with
          simple rules and no installation.
        </MainSubtitle>
        <CTA>
          <ResponsiveButtonBox>
            <Button variant="primary" onClick={hostGame}>
              Play Now
            </Button>
            <InstallButton />
          </ResponsiveButtonBox>
        </CTA>
      </HeroSection>
      <Section>
        <h2>No props</h2>
        <p>
          No pens, papers, bowls, kitchen timers and unreadable handwriting. All
          you need is a few people with phones. Together in the same room or
          online - just share a QR code and start playing!
        </p>
      </Section>
      <Section>
        <h2>It's all in the words</h2>
        <p>
          The game starts with everyone submitting their words. These words will
          be mixed together and used throughout the game so get creative and
          witty.
        </p>
      </Section>
      <Section>
        <h2>So many ways to communicate</h2>
        <p>
          You'll be taking turns guessing and expressing the words. Each round,
          in a new way: expaining the word, doing charades or communicating the
          meaning with only one other word.
        </p>
      </Section>
      <Section>
        <h2>Spice it up with competition</h2>
        <p>
          Earn scores for correct words and get higher in personal and team
          ratings. Some friendly competition never hurts, right?
        </p>
      </Section>
      <CTA>
        <Button variant="primary" onClick={hostGame}>
          Play Now
        </Button>
      </CTA>
    </Container>
  )
}
