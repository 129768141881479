export const CloseIcon = (props: any) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.9375 25L41.0625 11.8959C41.4548 11.5036 41.6752 10.9715 41.6752 10.4167C41.6752 9.86189 41.4548 9.32982 41.0625 8.93752C40.6702 8.54522 40.1381 8.32483 39.5833 8.32483C39.0285 8.32483 38.4965 8.54522 38.1042 8.93752L25 22.0625L11.8958 8.93752C11.5035 8.54522 10.9715 8.32483 10.4167 8.32483C9.86188 8.32483 9.3298 8.54522 8.93751 8.93752C8.54521 9.32982 8.32481 9.86189 8.32481 10.4167C8.32481 10.9715 8.54521 11.5036 8.93751 11.8959L22.0625 25L8.93751 38.1042C8.74224 38.2979 8.58725 38.5283 8.48148 38.7821C8.37571 39.036 8.32126 39.3083 8.32126 39.5834C8.32126 39.8584 8.37571 40.1307 8.48148 40.3846C8.58725 40.6384 8.74224 40.8688 8.93751 41.0625C9.13118 41.2578 9.3616 41.4128 9.61547 41.5185C9.86934 41.6243 10.1416 41.6788 10.4167 41.6788C10.6917 41.6788 10.964 41.6243 11.2179 41.5185C11.4717 41.4128 11.7022 41.2578 11.8958 41.0625L25 27.9375L38.1042 41.0625C38.2979 41.2578 38.5283 41.4128 38.7821 41.5185C39.036 41.6243 39.3083 41.6788 39.5833 41.6788C39.8584 41.6788 40.1307 41.6243 40.3845 41.5185C40.6384 41.4128 40.8688 41.2578 41.0625 41.0625C41.2578 40.8688 41.4128 40.6384 41.5185 40.3846C41.6243 40.1307 41.6788 39.8584 41.6788 39.5834C41.6788 39.3083 41.6243 39.036 41.5185 38.7821C41.4128 38.5283 41.2578 38.2979 41.0625 38.1042L27.9375 25Z"
      fill="#282B2F"
    />
  </svg>
)
