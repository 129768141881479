import styled from "styled-components"
import { black } from "./colors"
import { Centered } from "./Centered"

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: ${black};
  box-sizing: border-box;
  max-width: 800px;
  margin: auto;
`

export const ContentContainer = styled(Centered)`
  color: ${black};
  padding-top: 10px;
  padding-bottom: 30px;
  padding-right: 20px;
  padding-left: 20px;
  flex-grow: 1;
`
