import { Scene } from "../../models/PlayerScene"
import { useEffect, useState } from "react"
import { PlayerAPI } from "./PlayerAPI/Interface"
import { PlayerScreen } from "./screens"
import { useParams } from "react-router-dom"
import { useAuth } from "./hooks/useAuth"
import { GameInfo } from "../../models/GameInfo"
import { PlayersButton } from "./components/PlayersList/PlayersButton"

interface PlayerSessionProps {
  playerId: string
  api: PlayerAPI
  connected: boolean
}

export const PlayerSession = ({
  playerId,
  api,
  connected,
}: PlayerSessionProps) => {
  const [scene, setScene] = useState<Scene>({
    sceneName: "Waiting",
    message: "Joining the game...",
  })
  const [gameInfo, setGameInfo] = useState<GameInfo | null>(null)

  const { gameId } = useParams()

  const auth = useAuth()

  useEffect(() => {
    return api.subscribeToScene((sceneUpdate) => {
      console.log({ sceneUpdate })
      setScene(sceneUpdate)
    })
  }, [api])

  useEffect(() => {
    return api.subscribeToGame((gameUpdate) => {
      console.log({ gameUpdate })
      setGameInfo(gameUpdate)
    })
  }, [api])

  useEffect(() => {
    if (connected && gameId) {
      console.log("joining game", gameId)
      api.joinGame(gameId, (error) =>
        setScene({
          sceneName: "Error",
          error,
        })
      )
    }
  }, [api, playerId, gameId, connected])

  const isHost = gameInfo?.hostId === playerId

  return (
    <>
      <PlayerScreen
        scene={scene}
        api={api}
        gameId={gameId}
        userInfo={auth.user}
        gameInfo={gameInfo}
      />
      {gameInfo && (
        <PlayersButton
          players={gameInfo.players}
          api={api}
          enableAdmin={isHost}
        />
      )}
    </>
  )
}
