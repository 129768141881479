import { Centered } from "../components/Centered"
import { WaitingScene } from "../../../models/PlayerScene"
import { PlayerAPI } from "../PlayerAPI/Interface"
import { Spinner } from "../components/Spinner"
import { Title } from "../components/Title"

interface Props {
  scene: WaitingScene
  api: PlayerAPI
}

export const WaitingScreen = ({ scene: { message } }: Props) => {
  return (
    <Centered>
      <Spinner />
      <Title>{message}</Title>
    </Centered>
  )
}
